import { gql } from 'apollo-angular';

import { Customer } from '@frontend/models';

export interface GraphQLResponseCustomerDetails {
  customerDetails: Customer;
}

export const GET_CUSTOMER_DETAILS = gql`
  query GetCustomerDetails($id: String!) {
    customerDetails(id: $id) {
      id
      title
      firstname
      lastname
      birthdate
      nationality
      contact {
        phone
        email
        address {
          country
          city
          zipCode
          streetName
          buildingNumber
          doorNumber
          stairway
        }
      }
      customerDetails {
        maritalStatus
        hasChildren
        childrenAmountAge
        employer
        employerAddress
        employedSince
        monthlyIncome
      }
      dataPrivacy {
        hasAccepted
        admonitionRightOfWithdrawal
        expenseAllowance
      }
      questionnaire {
        lastLandlord
        moveInAmount
        movingReason
        animals
        animalType
        musician
        musicType
        smoker
        commercialUsage
        primaryResidence
        residentialCommunity
        canAffordRentAndDeposit
        acceptCreditCheck
        hasBankruptcyProceedings
        hasLawsuit
        noMisstatements
        hasEnergyPass
      }
      pep {
        isPep
        wasPepLastYear
        isFamilyMemberPep
        isKnownToBeCloseToPep
        firstName
        lastName
      }
      moneyLaundering {
        residentPerson
        investmentIncome
        estateSales
        endowment
        inheritance
        savings
        employment
        investments
        pension
        loan
        other
        otherDetails
      }
    }
  }
`;
