import { createAction, props } from '@ngrx/store';
import {
    Customer,
    PropertyOverview,
    PropertyOverviewDocuments,
    RentalOfferPreviewDocument,
    BuyingOfferPreviewDocument,
    OverviewFeedback,
    BusinessCustomerWithCustomerDetails,
    ViewingPassPreviewDocument
} from '@frontend/models';

export const LoadPropertyOverviews = createAction(
    '[PropertyOverview] Load Property Overviews',
);
export const LoadPropertyOverviewsFailed = createAction('[PropertyOverview] Failed to load Property Overviews');
export const LoadPropertyOverviewsSucceeded = createAction(
    '[PropertyOverview] Succeeded to load Property Overviews',
    props<{ payload: PropertyOverview[] }>()
);

export const LoadPropertyOverview = createAction(
  '[PropertyOverview] Load Property Overview',
  props<{ accessId: string }>(),
);
export const LoadPropertyOverviewFailed = createAction('[PropertyOverview] Failed to load Property Overview');
export const LoadPropertyOverviewSucceeded = createAction(
  '[PropertyOverview] Succeeded to load Property Overview',
  props<{ payload: PropertyOverview }>()
);

export const LoadPropertyOverviewDocuments = createAction(
  '[PropertyOverview] Load Property Overview Documents',
  props<{ accessId: string }>(),
);
export const LoadPropertyOverviewDocumentsFailed = createAction('[PropertyOverview] Failed to load Property Overview Documents');
export const LoadPropertyOverviewDocumentsSucceeded = createAction(
  '[PropertyOverview] Succeeded to load Property Overview Documents',
  props<{ payload: PropertyOverviewDocuments }>()
);

export const LoadOverviewFeedback = createAction(
  '[PropertyOverview] Load Feedback',
  props<{ accessId: string }>(),
);
export const LoadOverviewFeedbackFailed = createAction('[PropertyOverview] Failed to load Overview Feedback');
export const LoadOverviewFeedbackSucceeded = createAction(
  '[PropertyOverview] Succeeded to load Overview Feedback',
  props<{ payload: OverviewFeedback }>()
);

export const GenerateRentalOfferPreviewDocument = createAction(
  '[PropertyOverview] Generate Rental Offer Preview Document',
  props<{ accessId: string }>(),
);
export const GenerateRentalOfferPreviewDocumentFailed = createAction('[PropertyOverview] Generate Rental Offer Preview Document Failed');
export const GenerateRentalOfferPreviewDocumentSucceeded = createAction(
  '[PropertyOverview] Generate Rental Offer Preview Document Succeeded',
  props<{ payload: RentalOfferPreviewDocument }>()
);

export const GenerateBuyingOfferPreviewDocument = createAction(
  '[PropertyOverview] Generate Buying Offer Preview Document',
  props<{ accessId: string }>(),
);
export const GenerateBuyingOfferPreviewDocumentFailed = createAction('[PropertyOverview] Generate Buying Offer Preview Document Failed');
export const GenerateBuyingOfferPreviewDocumentSucceeded = createAction(
  '[PropertyOverview] Generate Buying Offer Preview Document Succeeded',
  props<{ payload: BuyingOfferPreviewDocument }>()
);

export const GenerateViewingPassPreviewDocument = createAction(
    '[PropertyOverview] Generate Viewing Pass Preview Document',
    props<{ accessId: string }>(),
);
export const GenerateViewingPassPreviewDocumentFailed = createAction('[PropertyOverview] Generate Viewing Pass Preview Document Failed');
export const GenerateViewingPassPreviewDocumentSucceeded = createAction(
    '[PropertyOverview] Generate Viewing Pass Preview Document Succeeded',
    props<{ payload: ViewingPassPreviewDocument }>()
);

export const LoadPropertyOverviewCustomers = createAction(
  '[PropertyOverview] Load Property Overview Customers',
  props<{ ids: string[] }>(),
);
export const LoadPropertyOverviewCustomersFailed = createAction('[PropertyOverview] Failed to load Property Overview Customers');
export const LoadPropertyOverviewCustomersSucceeded = createAction(
  '[PropertyOverview] Succeeded to load Property Overview Customers',
  props<{ payload: Customer[] }>()
);

export const LoadPropertyOverviewBusinessCustomer = createAction(
  '[PropertyOverview] Load Property Overview Business Customer',
  props<{ id: string }>(),
);
export const LoadPropertyOverviewBusinessCustomerFailed = createAction('[PropertyOverview] Failed to load Property Overview Business Customer');
export const LoadPropertyOverviewBusinessCustomerSucceeded = createAction(
  '[PropertyOverview] Succeeded to load Property Overview Business Customer',
  props<{ payload: BusinessCustomerWithCustomerDetails }>()
);

export const UpdateCustomersData = createAction(
  '[PropertyOverview] Update Customers Data',
  props<{
    payload: {
      customers: Customer[],
      businessCustomer?: BusinessCustomerWithCustomerDetails,
    }
  }>()
);

export const UpdateCustomerLanguage = createAction(
    '[PropertyOverview] Update Customer Language',
    props<{ accessId: string, customerLanguage: string }>(),
);
export const UpdateCustomerLanguageSucceeded = createAction(
    '[PropertyOverview] Update Customer Language Succeeded',
    props<{ customerLanguage: string }>(),
);
export const UpdateCustomerLanguageFailed = createAction(
    '[PropertyOverview] Update Customer Language Failed',
);