import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { featureGuard } from "@frontend/guards";
import { environment } from "@frontend/envs/environment";

import { LoadingComponent } from "./pages/loading/loading.component";
import { HomeComponent } from "@frontend/pages/home";
import {AuthGuard} from "./auth.guard";

const routes: Routes = [
  {
    path: '',
    // pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'loading',
    component: LoadingComponent,
    canActivate: [featureGuard],
    data: { allow: environment.staticToggles.enableLoadingPage }
  },
  {
    path: 'theme-demo',
    loadChildren: () => import('./pages/theme-demo/theme-demo.module').then(m => m.ThemeDemoModule),
  },
  {
    path: 'overview/:id',
    loadChildren: () => import('./pages/property-overview-container/container.module')
      .then(m => m.PropertyOverviewContainerModule),
  },
  {
    path: 'new-offer/:objectId',
    loadChildren: () => import('./pages/new-offer/new-offer.module')
      .then(m => m.NewOfferModule),
  },
  {
    path: 'public-property/:objectId',
    loadChildren: () => import('./pages/public-property/public-property.module')
      .then(m => m.PublicPropertyModule),
  },
  {
    path: 'owner/:id/overview',
    loadChildren: () => import('./pages/owner-overview-container/container.module')
      .then(m => m.OwnerOverviewContainerModule),
  },
  {
    path: 'valuation',
    loadChildren: () => import('./valuation/valuation.module')
      .then(m => m.ValuationModule),
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }