import {Injectable} from "@angular/core";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {KeycloakProfile} from "keycloak-js";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public isLoggedIn = false;
    public profile!: KeycloakProfile;

    get authenticated(): boolean {
        return this._keycloak.getKeycloakInstance().authenticated ?? false;
    }

    constructor(private readonly _keycloak: KeycloakService) {
        this._keycloak.keycloakEvents$.subscribe((e) => {
            if (e.type === KeycloakEventType.OnAuthSuccess) {
                this.isLoggedIn = true;

                this._keycloak.loadUserProfile().then((profile) => {
                    this.profile = profile;
                });
            }
            if (e.type === KeycloakEventType.OnTokenExpired) {
                console.log('Token expired. Refreshing...');
                this._keycloak.updateToken(30).then((r) => console.log('Token refreshed:', r));
            }
        });

        const isLoggedIn = this._keycloak.isLoggedIn();
        if (isLoggedIn) {
            this._keycloak.loadUserProfile().then((profile) => {
                this.profile = profile;
                this.isLoggedIn = true;
            });
        } else {
            console.log('User is not authenticated.');
        }

    }

    login(): void {
        const options = {
            redirectUri: window.location.origin + '/dashboard',
        }
        this._keycloak.login(options).then(r => console.log("Login", r));
    }

    loginWithAccessId(accessId: string): void {
        const options = {
            redirectUri: window.location.origin + '/dashboard/' + accessId,
        }
        this._keycloak.login(options).then(r => console.log("Login", r));
    }

    logout(): void {
        const logoutBaseUrl = this._keycloak.getKeycloakInstance().createLogoutUrl();
        this._keycloak.logout(logoutBaseUrl).then(r => console.log("Logout", r));
    }

    get id(): string | undefined {
        return this.profile?.id;
    }

    get name(): string | undefined {
        return this.profile?.username;
    }

    get email(): string | undefined {
        return this.profile?.email;
    }

    get firstName(): string | undefined {
        return this.profile?.firstName;
    }

    get lastName(): string | undefined {
        return this.profile?.lastName;
    }


}