import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import {environment} from "@frontend/envs/environment";
import {EnvironmentType} from "@frontend/envs/environment-config";

@Component({
    selector: 'app-responsive-helper',
    templateUrl: './responsive-helper.component.html',
    standalone: true,
    imports: [NgIf],
})
export class ResponsiveHelperComponent implements OnInit {
  public env: any = environment.environment;

  constructor() {}

  ngOnInit(): void {}

    protected readonly EnvironmentType = EnvironmentType;
}
