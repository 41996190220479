import {Injectable} from "@angular/core";
import {Apollo} from "apollo-angular";
import {
    GET_PRIVATE_CUSTOMER_DETAILS,
    GraphQLResponsePrivateCustomerDetails
} from "@frontend/graphql/query";
import {map} from "rxjs";

@Injectable({providedIn: 'root'})
export class PrivateCustomerService {
    constructor(private readonly _apollo: Apollo) {
    }

    getPrivateCustomerData$() {
        return this._apollo.query<
            GraphQLResponsePrivateCustomerDetails
        >({
            query: GET_PRIVATE_CUSTOMER_DETAILS,
            fetchPolicy: 'no-cache',
        }).pipe(map((m) => m.data.privateCustomerDetails));
    }

}