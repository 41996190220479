import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, combineLatest, switchMap, withLatestFrom} from 'rxjs/operators';
import { PropertyOverviewActions } from './action-types';
import { CustomerService, PropertyService, ThemeService } from '@frontend/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import {TranslationService} from "../../i18n/TranslationService";

@Injectable()
export class PropertyOverviewEffects {
    public loadPropertyOverviews$ = createEffect(() =>
        this._actions$.pipe(
            ofType(PropertyOverviewActions.LoadPropertyOverviews),
            switchMap(() =>
                this._propertyService.getPropertyOverviews$().pipe(
                    switchMap((data) =>
                            [PropertyOverviewActions.LoadPropertyOverviewsSucceeded({ payload: data })]
                    ),
                    catchError(() => {
                        const message = this._translationService.instant('alerts.property_not_found');
                        const action = this._translationService.instant('alerts.close');
                        this._snackBar.open(message, action);

                        return [PropertyOverviewActions.LoadPropertyOverviewsFailed()];
                    })
                )
            )
        )
    );

  public loadPropertyOverview$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadPropertyOverview),
      switchMap(({ accessId }) =>
        this._propertyService.getPropertyOverviewById$(accessId).pipe(
          switchMap((data) => {
            this._themeService.setCustomerPortalLanguage(data?.agent, data?.customerLanguage)
            this._themeService.setAgentRelatedTheme(data?.agent);

            let customerLoadAction;
            if (data.customerType === 'business') {
              customerLoadAction = PropertyOverviewActions.LoadPropertyOverviewBusinessCustomer({ id: data.businessCustomerId ?? '' });
            } else {
              customerLoadAction = PropertyOverviewActions.LoadPropertyOverviewCustomers({ ids: data.customersIds ?? [] });
            }

            return [
              PropertyOverviewActions.LoadPropertyOverviewSucceeded({ payload: data }),
              PropertyOverviewActions.LoadPropertyOverviewDocuments({ accessId }),
              PropertyOverviewActions.LoadOverviewFeedback({ accessId }),
              customerLoadAction,
            ];
          }),
          catchError(() => {
              const message = this._translationService.instant('alerts.property_not_found');
              const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action);

            return [PropertyOverviewActions.LoadPropertyOverviewFailed()];
          })
        )
      )
    )
  );

  public loadPropertyOverviewDocuments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadPropertyOverviewDocuments),
      switchMap(({ accessId }) =>
        this._propertyService.getPropertyOverviewDocuments$(accessId).pipe(
          switchMap((data) => [
            PropertyOverviewActions.LoadPropertyOverviewDocumentsSucceeded({ payload: data })
          ]),
          catchError(() => [PropertyOverviewActions.LoadPropertyOverviewDocumentsFailed()])
        )
      )
    )
  );

  public loadOverviewFeedback$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadOverviewFeedback),
      switchMap(({ accessId }) =>
        this._propertyService.getOverviewFeedback$(accessId).pipe(
          switchMap((data) => [
            PropertyOverviewActions.LoadOverviewFeedbackSucceeded({ payload: data })
          ]),
          catchError(() => [PropertyOverviewActions.LoadOverviewFeedbackFailed()])
        )
      )
    )
  );

public generateViewingPassPreviewDocument$ = createEffect(() =>
    this._actions$.pipe(
        ofType(PropertyOverviewActions.GenerateViewingPassPreviewDocument),
        switchMap(({ accessId }) =>
            this._propertyService.getViewingPassDocumentPreview$(accessId).pipe(
                switchMap((data) => [
                    PropertyOverviewActions.GenerateViewingPassPreviewDocumentSucceeded({ payload: data })
                ]),
                catchError(() => {
                    const message = this._translationService.instant('alerts.vp_loading_failed');
                    const action = this._translationService.instant('alerts.close');
                    this._snackBar.open(message, action);
                    return [PropertyOverviewActions.GenerateViewingPassPreviewDocumentFailed()];
                })
            )
        )
    )
);

  public generateRentalOfferPreviewDocument$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.GenerateRentalOfferPreviewDocument),
      switchMap(({ accessId }) =>
        this._propertyService.getRentalOfferDocumentPreview$(accessId).pipe(
          switchMap((data) => [
            PropertyOverviewActions.GenerateRentalOfferPreviewDocumentSucceeded({ payload: data })
          ]),
          catchError(() => {
              const message = this._translationService.instant('alerts.offer_loading_failed');
              const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action);
            return [PropertyOverviewActions.GenerateRentalOfferPreviewDocumentFailed()];
          })
        )
      )
    )
  );

  public generateBuyingOfferPreviewDocument$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.GenerateBuyingOfferPreviewDocument),
      switchMap(({ accessId }) =>
        this._propertyService.getBuyingOfferDocumentPreview$(accessId).pipe(
          switchMap((data) => [
            PropertyOverviewActions.GenerateBuyingOfferPreviewDocumentSucceeded({ payload: data })
          ]),
          catchError(() => {
              const message = this._translationService.instant('alerts.offer_loading_failed');
              const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action);
            return [PropertyOverviewActions.GenerateBuyingOfferPreviewDocumentFailed()];
          })
        )
      )
    )
  );

  public loadPropertyOverviewCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadPropertyOverviewCustomers),
      switchMap(({ ids }) =>
        forkJoin(ids.map(id => this._customerService.getCustomerDetails$(id))).pipe(
          switchMap((data) => [
            PropertyOverviewActions.LoadPropertyOverviewCustomersSucceeded({ payload: data })
          ]),
          catchError(() => [PropertyOverviewActions.LoadPropertyOverviewCustomersFailed()])
        )
      )
    )
  );

  public loadPropertyOverviewBusinessCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadPropertyOverviewBusinessCustomer),
      switchMap(({ id }) =>
        this._customerService.getBusinessCustomerDetails$(id))).pipe(
          switchMap((data) => [
            PropertyOverviewActions.LoadPropertyOverviewBusinessCustomerSucceeded({ payload: data })
          ]),
          catchError(() => [PropertyOverviewActions.LoadPropertyOverviewBusinessCustomerFailed()]),
        ),
  );

    public updateCustomerLanguage$ = createEffect(() =>
        this._actions$.pipe(
         ofType(PropertyOverviewActions.UpdateCustomerLanguage),
         switchMap(({ accessId, customerLanguage}) =>
             forkJoin(
                 [this._propertyService.updateCustomerLanguage$(accessId, customerLanguage), this._propertyService.getPropertyOverviewById$(accessId)]
             ).pipe(
                switchMap(([language, overview]) => {
                    this._themeService.setCustomerPortalLanguage(overview?.agent, language);
                    return [PropertyOverviewActions.UpdateCustomerLanguageSucceeded({ customerLanguage })];
                }),
                catchError(() => [PropertyOverviewActions.UpdateCustomerLanguageFailed()]),
                )
            )
        )
    );

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackBar: MatSnackBar,
    private readonly _themeService: ThemeService,
    private readonly _customerService: CustomerService,
    private readonly _propertyService: PropertyService,
    private readonly _translationService: TranslationService
  ) { }
}
