import { KeycloakService } from 'keycloak-angular';

const clientId = 'propup-frontend';
const issuer = 'https://auth.staging.propup.at/auth/realms/PROPUP_CUSTOMERS';
const initializeKeycloak = (keycloakService: KeycloakService) => {
    return () => {
        return keycloakService.init({
            config: {
                url: 'https://auth.staging.propup.at/auth',
                realm: 'PROPUP_CUSTOMERS',
                clientId: 'propup-frontend',
            },
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
                checkLoginIframe: false,
            },
            bearerExcludedUrls: ['/assets'],
        });
    };
};

export const keycloak = {
  clientId,
  issuer,
  initializeKeycloak,
};
