import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DateAdapter, MAT_DATE_LOCALE,} from "@angular/material/core";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {GraphQLModule} from "@frontend/graphql";
import {FormattingModule} from "@frontend/formatting";
import {HomeModule} from "@frontend/pages/home";
import {PropertyOverviewStoreModule} from "@frontend/store/property-overview";

import {registerLocaleData} from "@angular/common";
import localeDe from "@angular/common/locales/de";
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {OwnerOverviewStoreModule} from "@frontend/store/owner-overview";
import {GraphQlValuationModule} from "./valuation/graphql";
import {PublicPropertyOverviewStoreModule} from "@frontend/store/public-property-overview";
import {TranslationRootModule} from "./i18n/TranslationRootModule";
import {keycloak} from '../keycloak.gcp.staging.config';
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {environment} from "@frontend/envs/environment";
import {AngularSvgIconModule} from "angular-svg-icon";
import {EnvironmentType} from "@frontend/envs/environment-config";
import {ResponsiveHelperComponent} from "./dashboard/components/responsive-helper/responsive-helper.component";
import {PrivateCustomerStoreModule} from "@frontend/store/private-customer/private-customer-store.module";


registerLocaleData(localeDe);

@NgModule({ declarations: [AppComponent],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        GraphQLModule,
        GraphQlValuationModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        FormattingModule,
        MatMomentDateModule,
        HomeModule,
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        PropertyOverviewStoreModule,
        PublicPropertyOverviewStoreModule,
        OwnerOverviewStoreModule,
        NgxSkeletonLoaderModule.forRoot({animation: 'pulse'}),
        TranslationRootModule,
        KeycloakAngularModule,
        AngularSvgIconModule.forRoot(), ResponsiveHelperComponent,
        PrivateCustomerStoreModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de' },
        { provide: MAT_DATE_LOCALE, useValue: 'de-AT' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: DateAdapter,
            useFactory: () => {
                const dateAdapter = new MomentDateAdapter('de-at', { useUtc: true });
                dateAdapter.getFirstDayOfWeek = () => 1;
                dateAdapter.format = (date, displayFormat) => {
                    if (!date)
                        return '';
                    return date.format('DD.MM.YYYY');
                };
                return dateAdapter;
            },
            deps: []
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({ showDialog: false }),
        },
        { provide: Sentry.TraceService, deps: [Router] },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
        ...(environment.environment !== EnvironmentType.PROD ? [
            {
                provide: APP_INITIALIZER,
                useFactory: keycloak.initializeKeycloak,
                deps: [KeycloakService],
                multi: true,
            },
        ] : []),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}