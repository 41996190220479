import { gql } from 'apollo-angular';
import {PrivateCustomer} from "@frontend/store/private-customer/private-customer.model";

export interface GraphQLResponsePrivateCustomerDetails {
  privateCustomerDetails: PrivateCustomer;
}

export const GET_PRIVATE_CUSTOMER_DETAILS = gql`
  query GetPrivateCustomerDetails {
    privateCustomerDetails {
        userId
        firstName
        lastName
        contact {
            phone
            email
        }
    }
  }
`;
