import { Injectable } from '@angular/core';

import { AuthService } from './services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private _authService: AuthService) { }

    canActivate(): boolean {
        return this.checkAuth();
    }

    canLoad(): boolean {
        return this.checkAuth();
    }

    checkAuth(): boolean {
        if (!this._authService.authenticated) {
            this._authService.login();

            return false;
        }

        return true;
    }
}
