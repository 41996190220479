import { Injectable } from '@angular/core';
import { Store, ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

import { PropertyOverviewActions } from './action-types';
import { PropertyOverviewFeatureState, propertyOverviewSelectors } from './index';
import { BusinessCustomerWithCustomerDetails, Customer } from '@frontend/models';

@Injectable({ providedIn: 'root' })
export class PropertyOverviewStoreFacade {
  propertyOverviews$ = this._store$.select(propertyOverviewSelectors.propertyOverviews);
  propertyOverviewsLoading$ = this._store$.select(propertyOverviewSelectors.propertyOverviewsLoading);
  propertyOverviewsError$ = this._store$.select(propertyOverviewSelectors.propertyOverviewsError);

  overviewFeedback$ = this._store$.select(propertyOverviewSelectors.overviewFeedback);
  propertyOverview$ = this._store$.select(propertyOverviewSelectors.propertyOverview);
  propertyOverviewLoading$ = this._store$.select(propertyOverviewSelectors.propertyOverviewLoading);
  propertyOverviewError$ = this._store$.select(propertyOverviewSelectors.propertyOverviewError);
  propertyOverviewDocuments$ = this._store$.select(propertyOverviewSelectors.propertyOverviewDocuments);
  propertyOverviewCustomerData$ = this._store$.select(propertyOverviewSelectors.overviewCustomerData);
  rentalOfferPreviewDocumentGeneration$ = this._store$.select(propertyOverviewSelectors.rentalOfferPreviewDocumentGeneration);
  buyingOfferPreviewDocumentGeneration$ = this._store$.select(propertyOverviewSelectors.buyingOfferPreviewDocumentGeneration);
  viewingPassPreviewDocumentGeneration$ = this._store$.select(propertyOverviewSelectors.viewingPassPreviewDocumentGeneration);

  constructor(
    private readonly _store$: Store<PropertyOverviewFeatureState>,
    private readonly actionsListener$: ActionsSubject,
  ) { }

  loadPropertyOverviews(): void {
    this._store$.dispatch(PropertyOverviewActions.LoadPropertyOverviews());
  }

  loadPropertyOverview(accessId: string): void {
    this._store$.dispatch(PropertyOverviewActions.LoadPropertyOverview({ accessId }));
  }

  generateRentalOfferPreviewDocument(accessId: string): void {
    this._store$.dispatch(PropertyOverviewActions.GenerateRentalOfferPreviewDocument({ accessId }));
  }

  generateBuyingOfferPreviewDocument(accessId: string): void {
    this._store$.dispatch(PropertyOverviewActions.GenerateBuyingOfferPreviewDocument({ accessId }));
  }

  generateViewingPassPreviewDocument(accessId: string): void {
    this._store$.dispatch(PropertyOverviewActions.GenerateViewingPassPreviewDocument({accessId}));
  }

  updateCustomersData(data: { customers: Customer[], businessCustomer?: BusinessCustomerWithCustomerDetails }): void {
    this._store$.dispatch(PropertyOverviewActions.UpdateCustomersData({
      payload: {
        customers: data.customers,
        businessCustomer: data.businessCustomer,
      }
    }));
  }

  updateCustomerLanguage$(accessId: string, customerLanguage: string): void {
    this._store$.dispatch(PropertyOverviewActions.UpdateCustomerLanguage({ accessId, customerLanguage }));
  }

  rentalOfferPreviewDocumentGenerated$() {
    return this.actionsListener$.pipe(
      ofType(PropertyOverviewActions.GenerateRentalOfferPreviewDocumentSucceeded)
    );
  }

  buyingOfferPreviewDocumentGenerated$() {
    return this.actionsListener$.pipe(
      ofType(PropertyOverviewActions.GenerateBuyingOfferPreviewDocumentSucceeded)
    );
  }

  viewingPassPreviewDocumentGenerated$() {
    return this.actionsListener$.pipe(
      ofType(PropertyOverviewActions.GenerateViewingPassPreviewDocumentSucceeded)
    );
  }
}
