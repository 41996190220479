import { AppModule } from "./app/app.module";
import { environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";

if (environment.staticToggles.enableLoggingTracing) {
  Sentry.init({
    dsn: 'https://29c2d5eafe0a445ca9f93e84f5f5d6bb@o1307589.ingest.sentry.io/4505079466360832',
    environment: environment.SENTRY_ENVIRONMENT,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracePropagationTargets: ['localhost', /^https:\/\/www\.propup\.at/, /^https:\/\/app\.propup\.at/],
    tracesSampleRate: 0.8,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));